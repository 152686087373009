import React, { useState, useEffect, useRef } from "react";

const ExpertSelectionScreen = () => {
  const [selectedExpertType, setSelectedExpertType] = useState(null);
  const [selectedExpert, setSelectedExpert] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasStartedChat, setHasStartedChat] = useState(false);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [conversations, setConversations] = useState([]);

  const messagesEndRef = useRef(null);

  // Define expert lists with their types
  const subjectExperts = [
    {
      id: "gtm",
      name: "Business Strategy",
      emoji: "🎯",
      type: "subject",
      description: "A seasoned business consultant with over 20 years of experience in helping companies scale, optimize operations, and achieve market leadership"
    },
    {
      id: "tech",
      name: "Growth Marketing",
      emoji: "💻",
      type: "subject",
      description: "A veteran growth marketer who has helped 100+ startups achieve product-market fit and scale their user acquisition"
    },
    {
      id: "marketing",
      name: "Financial Planning",
      emoji: "📢",
      type: "subject",
      description: "A CFO with extensive experience in startup financial planning, fundraising, and strategic financial management"
    },
    {
      id: "sales",
      name: "Operations Management",
      emoji: "🤝",
      type: "subject",
      description: "A COO with expertise in process optimization, supply chain management, and scaling operational infrastructure"
    },
    {
      id: "product",
      name: "Legal & Compliance",
      emoji: "⚡",
      type: "subject",
      description: "A startup-focused attorney with deep experience in business structure, IP protection, and regulatory compliance"
    },
    {
      id: "gtm",
      name: "Sales Strategy",
      emoji: "🎯",
      type: "subject",
      description: "A sales leader who has built and scaled sales teams from zero to $100M+ revenue"
    },
    {
      id: "tech",
      name: "Customer Experience",
      emoji: "💻",
      type: "subject",
      description: "A CX expert specializing in customer journey mapping, satisfaction metrics, and loyalty programs"
    },
    {
      id: "marketing",
      name: "Team Management",
      emoji: "📢",
      type: "subject",
      description: "An HR executive experienced in startup hiring, retention, and culture building"
    },
    {
      id: "sales",
      name: "Strategic Planning",
      emoji: "🤝",
      type: "subject",
      description: "A strategy consultant specialized in market analysis, competitive positioning, and growth planning"
    },
    {
      id: "product",
      name: "Innovation Management",
      emoji: "⚡",
      type: "subject",
      description: "A product strategist focused on R&D, innovation processes, and new market opportunities"
    },
    {
      id: "gtm",
      name: "Brand Building",
      emoji: "🎯",
      type: "subject",
      description: "A brand strategist with expertise in brand identity, positioning, and market perception"
    },
    {
      id: "tech",
      name: "Risk Management",
      emoji: "💻",
      type: "subject",
      description: "A risk assessment expert specializing in business continuity, crisis management, and mitigation strategies"
    }
  ];
  
  const personalities = [
    {
      id: "elon",
      name: "Elon Musk",
      emoji: "🚀",
      type: "personality",
      description: "Serial entrepreneur and innovator known for pushing boundaries in electric vehicles, space exploration, and sustainable energy"
    },
    {
      id: "naval",
      name: "Naval Ravikant",
      emoji: "🧠",
      type: "personality",
      description: "AngelList founder known for philosophical approach to wealth creation, building judgment, and decision-making"
    },
    {
      id: "sam",
      name: "Sam Altman",
      emoji: "💡",
      type: "personality",
      description: "Tech visionary with deep expertise in startup scaling, AI, and building transformative companies"
    },
    {
      id: "paul",
      name: "Paul Graham",
      emoji: "📝",
      type: "personality",
      description: "YC founder known for practical startup wisdom, direct communication style, and emphasis on building things people want"
    },
    {
      id: "patrick",
      name: "Patrick Collison",
      emoji: "💳",
      type: "personality",
      description: "Stripe founder known for systematic thinking, technical depth, and building highly efficient organizations"
    },
    {
      id: "marc",
      name: "Marc Andreessen",
      emoji: "🌐",
      type: "personality",
      description: "Pioneering tech founder and investor known for deep industry insights and future-focused thinking"
    },
    {
      id: "reid",
      name: "Reid Hoffman",
      emoji: "🔗",
      type: "personality",
      description: "LinkedIn founder and scaling expert known for strategic networking and blitzscaling methodology"
    },
    {
      id: "tobi",
      name: "Tobi Lütke",
      emoji: "🛍️",
      type: "personality",
      description: "Shopify founder known for building sustainable companies and strong engineering cultures"
    },
    {
      id: "brian",
      name: "Brian Chesky",
      emoji: "🏠",
      type: "personality",
      description: "Airbnb founder expert in customer experience design and building unique company cultures"
    },
    {
      id: "jason",
      name: "Jason Fried",
      emoji: "📊",
      type: "personality",
      description: "Basecamp founder known for sustainable business building and alternative growth approaches"
    },
    {
      id: "sahil",
      name: "Sahil Bloom",
      emoji: "📈",
      type: "personality",
      description: "Modern business thinker known for breaking down complex concepts into actionable frameworks"
    },
    {
      id: "pieter",
      name: "Pieter Levels",
      emoji: "🌏",
      type: "personality",
      description: "Solo founder known for building in public and minimal viable products"
    },
    {
      id: "daniel",
      name: "Daniel Vassallo",
      emoji: "🎯",
      type: "personality",
      description: "AWS engineer turned creator known for small bets strategy and alternative business models"
    },
    {
      id: "rob",
      name: "Rob Walling",
      emoji: "💻",
      type: "personality",
      description: "MicroConf founder known for bootstrapped SaaS expertise and sustainable growth strategies"
    },
    {
      id: "kunal",
      name: "Kunal Shah",
      emoji: "💫",
      type: "personality",
      description: "CRED founder known for behavioral insights and emerging market opportunities"
    }
  ];
  

  // Helper function to get emoji
  const getExpertEmoji = (expertId) => {
    const allExperts = [...subjectExperts, ...personalities];
    const expert = allExperts.find((exp) => exp.id === expertId);
    return expert?.emoji || "👤";
  };


  // Reset all states when going back
  const handleBack = () => {
    setSelectedExpertType(null);
    setSelectedExpert(null);
    setMessages([]);
    setInputMessage("");
    setHasStartedChat(false);
    setCurrentConversationId(null); // Important: Reset conversation ID
    setIsDropdownOpen(false);
  };

  // Fetch conversations
  const fetchConversations = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/conversations`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setConversations(data);
    } catch (error) {
      console.error("Error fetching conversations:", error);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchConversations();
  }, []);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || !selectedExpert) return;

    const newUserMessage = { role: "user", content: inputMessage.trim() };

    const requestData = {
      conversationId: currentConversationId || null,
      expertType: selectedExpert.type,
      expertId: selectedExpert.id,
      expertName: selectedExpert.name,
      expertDescription: selectedExpert.description,
      messages: [...messages, newUserMessage],
    };

    console.log("Sending request data:", requestData); // Debug log

    setMessages((prev) => [...prev, newUserMessage]);
    setInputMessage("");
    setIsLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/expertChat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      const data = await response.json();
      console.log("Response data:", data); // Debug log

      if (data.message) {
        setMessages((prev) => [
          ...prev,
          { role: "assistant", content: data.message },
        ]);

        // If this is a new conversation
        if (data.conversationId) {
          setCurrentConversationId(data.conversationId);
          // Fetch updated conversations immediately
          await fetchConversations();
        }

        setHasStartedChat(true);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: "Sorry, I encountered an error. Please try again.",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle expert type selection
  const handleExpertTypeSelect = (type) => {
    setSelectedExpertType(type);
    setSelectedExpert(null);
    setMessages([]);
    setCurrentConversationId(null); // Reset conversation ID when changing type
    setHasStartedChat(false);
  };

  // Handle expert selection
  const handleExpertSelect = (expert) => {
    setSelectedExpert(expert);
    setIsDropdownOpen(false);
    setMessages([]);
    setCurrentConversationId(null); // Reset conversation ID when changing expert
    setHasStartedChat(false);
  };

  // Render previous chats with loading state
  const renderPreviousChats = () => (
    <div className="col-span-3">
      <div className="bg-pink-100 rounded-lg h-[calc(100vh-180px)] flex flex-col">
        {/* Fixed Header */}
        <div className="p-4 border-b border-pink-200 flex-shrink-0">
          <h2 className="font-semibold text-lg">Previous chats</h2>
        </div>

        {/* Scrollable Previous Chats */}
        <div className="flex-1 overflow-y-auto p-4 space-y-2">
          {conversations?.length > 0 ? (
            conversations.map((conv) => (
              <div
                key={conv._id}
                onClick={() => loadConversation(conv._id)}
                className={`flex items-center gap-2 p-3 rounded cursor-pointer
                  ${
                    currentConversationId === conv._id
                      ? "bg-pink-200"
                      : "hover:bg-pink-200/50"
                  }
                  transition-colors duration-200`}
              >
                <span className="text-xl flex-shrink-0">
                  {getExpertEmoji(conv.expertId)}
                </span>
                <div className="flex-1 min-w-0">
                  <div className="font-medium text-sm truncate">
                    {conv.expertName}
                  </div>
                  <div className="text-sm text-gray-600 truncate">
                    {conv.messages[0]?.content || "New conversation"}
                  </div>
                </div>
                {/* <span className="text-xs text-gray-500 flex-shrink-0">
                  {new Date(conv.createdAt).toLocaleDateString()}
                </span> */}
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center h-full text-gray-500">
              <span className="text-4xl mb-4">💭</span>
              <p className="text-center">No conversations yet.</p>
              <p className="text-center text-sm">
                Your chat history will appear here.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  // Load conversation function
  const loadConversation = async (conversationId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/conversations/${conversationId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      const data = await response.json();

      setSelectedExpertType(data.expertType);
      setSelectedExpert({
        id: data.expertId,
        name: data.expertName,
        type: data.expertType,
        emoji: getExpertEmoji(data.expertId),
      });
      setMessages(data.messages);
      setCurrentConversationId(conversationId);
      setHasStartedChat(true);
    } catch (error) {
      console.error("Error loading conversation:", error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Initial selection screen
  if (!selectedExpertType) {
    return (
      <div className="w-full h-full bg-white">
        <div className="max-w-7xl mx-auto p-6">
          <div className="grid grid-cols-12 gap-8">
            {renderPreviousChats()}
            <div className="col-span-9 flex items-center justify-center h-full">
              <div className="grid grid-cols-2 gap-12 w-4/5">
                <div
                  onClick={() => handleExpertTypeSelect("subject")}
                  className="bg-purple-100 rounded-xl p-12 text-center cursor-pointer 
                    hover:shadow-xl transition-all hover:scale-105 duration-200"
                >
                  <div className="flex justify-center mb-6">
                    <span className="text-6xl">👨‍🎓</span>
                  </div>
                  <h3 className="text-2xl font-semibold">Subject expert</h3>
                </div>

                <div
                  onClick={() => handleExpertTypeSelect("personality")}
                  className="bg-purple-100 rounded-xl p-12 text-center cursor-pointer 
                    hover:shadow-xl transition-all hover:scale-105 duration-200"
                >
                  <div className="flex justify-center mb-6">
                    <span className="text-6xl">🦉</span>
                  </div>
                  <h3 className="text-2xl font-semibold">Personality</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Chat interface
  return (
    <div className="w-full h-full bg-white">
      <div className="max-w-7xl mx-auto p-6">
        <div className="grid grid-cols-12 gap-8">
          {renderPreviousChats()}
          <div className="col-span-9">
            <div className="bg-gray-50 rounded-lg h-[calc(100vh-180px)] flex flex-col">
              {/* Chat Header - Fixed */}
              <div className="p-4 border-b bg-white rounded-t-lg flex-shrink-0">
                <div className="flex items-center justify-between">
                  <button
                    onClick={handleBack}
                    className="text-gray-600 hover:text-gray-800 mr-4 flex items-center gap-2"
                  >
                    <span>←</span>
                    <span>Back</span>
                  </button>
                  <div className="relative flex-1">
                    {/* Expert selection dropdown */}
                    <button
                      onClick={() =>
                        !hasStartedChat && setIsDropdownOpen(!isDropdownOpen)
                      }
                      className={`w-full flex items-center justify-between bg-gray-50 p-3 rounded-lg
                        ${!hasStartedChat ? "hover:bg-gray-100" : ""}
                        ${
                          hasStartedChat ? "cursor-default" : "cursor-pointer"
                        }`}
                      disabled={hasStartedChat}
                    >
                      {selectedExpert ? (
                        <div className="flex items-center gap-2">
                          <span className="text-xl">
                            {selectedExpert.emoji}
                          </span>
                          <span className="font-medium">
                            {selectedExpert.name}
                          </span>
                        </div>
                      ) : (
                        <span className="text-gray-500">
                          Select an expert...
                        </span>
                      )}
                      {!hasStartedChat && (
                        <span className="text-gray-400">▼</span>
                      )}
                    </button>

                    {/* Dropdown Menu */}
                    {isDropdownOpen && !hasStartedChat && (
                      <div className="absolute top-full left-0 right-0 mt-1 bg-white rounded-lg shadow-lg border z-10">
                        <div className="p-2">
                          {(selectedExpertType === "subject"
                            ? subjectExperts
                            : personalities
                          ).map((expert) => (
                            <div
                              key={expert.id}
                              onClick={() => handleExpertSelect(expert)}
                              className="flex items-center gap-2 p-2 hover:bg-gray-50 rounded cursor-pointer"
                            >
                              <span className="text-xl">{expert.emoji}</span>
                              <span className="font-medium">{expert.name}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Messages Area - Scrollable */}
              <div
                className="flex-1 overflow-y-auto p-4 scroll-smooth"
                style={{ scrollBehavior: "smooth" }}
              >
                {selectedExpert ? (
                  messages.length > 0 ? (
                    <div className="flex-1 overflow-y-auto p-4 space-y-4">
                      {messages.map((msg, index) => (
                        <div
                          key={index}
                          className={`flex ${
                            msg.role === "user"
                              ? "justify-end"
                              : "justify-start"
                          }`}
                        >
                          <div
                            className={`max-w-[70%] break-words whitespace-pre-wrap rounded-lg p-3 ${
                              msg.role === "user"
                                ? "bg-pink-500 text-white rounded-br-none ml-auto" // Added ml-auto
                                : "bg-gray-100 text-gray-800 rounded-bl-none mr-auto" // Added mr-auto
                            }`}
                            style={{ minWidth: "50px" }} // Added minimum width
                          >
                            {msg.content}
                          </div>
                        </div>
                      ))}
                      {isLoading && (
                        <div className="flex justify-start">
                          <div className="bg-gray-100 rounded-lg p-3 flex items-center gap-2">
                            <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" />
                            <div
                              className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"
                              style={{ animationDelay: "0.2s" }}
                            />
                            <div
                              className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"
                              style={{ animationDelay: "0.4s" }}
                            />
                          </div>
                        </div>
                      )}
                      <div ref={messagesEndRef} />
                    </div>
                  ) : (
                    <div className="h-full flex items-center justify-center text-gray-500">
                      <div className="text-center">
                        <span className="text-4xl block mb-4">
                          {selectedExpert.emoji}
                        </span>
                        <p className="text-lg">
                          Start chatting with {selectedExpert.name}
                        </p>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="h-full flex items-center justify-center text-gray-500">
                    <div className="text-center">
                      <span className="text-4xl block mb-4">
                        {selectedExpertType === "subject" ? "👨‍🎓" : "🦉"}
                      </span>
                      <p className="text-lg">
                        Select an expert to start chatting
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {/* Input Area - Fixed */}
              {selectedExpert && (
                <div className="p-4 border-t bg-white rounded-b-lg flex-shrink-0">
                  <form onSubmit={handleSendMessage} className="flex gap-2">
                    <button
                      type="submit"
                      disabled={isLoading || !inputMessage.trim()}
                      className="px-6 py-3 bg-pink-500 text-white rounded-lg hover:bg-pink-600 
                        disabled:bg-pink-300 disabled:cursor-not-allowed flex-shrink-0"
                    >
                      Send
                    </button>
                    <input
                      type="text"
                      value={inputMessage}
                      onChange={(e) => setInputMessage(e.target.value)}
                      placeholder={`Ask ${selectedExpert.name} anything...`}
                      className="flex-1 p-3 border rounded-lg"
                      disabled={isLoading}
                    />
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertSelectionScreen;
