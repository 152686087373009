import React, { useState, useEffect } from 'react';
import { Plus, X } from 'lucide-react';
import ChatWindow from './components/avatarChat';
import ExpertSelectionScreen from './components/talkToExpert';
import { AuthProvider, useAuth } from './context/AuthContext';
import AuthForm from './components/AuthForm';

const AddTaskModal = ({ isOpen, onClose, onAdd }) => {
  const [task, setTask] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (task.trim()) {
      onAdd(task.trim());
      setTask('');
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96 font-['Patrick_Hand']">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Add New Task</h3>
          <button onClick={onClose} className="hover:bg-gray-100 rounded-full p-1">
            <X size={20} />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={task}
            onChange={(e) => setTask(e.target.value)}
            placeholder="Enter your task"
            className="w-full p-2 border rounded mb-4"
            autoFocus
          />
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 rounded hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              Add Task
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
const TaskCard = ({ title, tasks = [],bgColor ,backColor, id, onDrop, onDragStart, onAddTask, onDeleteTask, onEditTask }) => {
  const [showModal, setShowModal] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [editText, setEditText] = useState('');
  
  const isTwoColumnLayout = id === 'week' || id === 'month';
  
  const handleDragStart = (e, info) => {
    // Only clear selection if not editing
    if (editingTask === null) {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    }
    
    onDragStart(e, info);
    e.target.style.opacity = '0.5';
  };

  const handleDragEnd = (e) => {
    e.target.style.opacity = '1';
  };
  
  return (
    <>
      <div 
        className={`rounded-lg p-4 ${backColor} shadow-md h-full flex flex-col`}
        onDrop={(e) => onDrop(e, id)}
        onDragOver={(e) => e.preventDefault()}
      >
        <div className="flex items-center justify-between mb-3">
          <h2 className="text-lg font-semibold select-none">{title}</h2>
          <button 
            onClick={() => setShowModal(true)}
            className="p-1 hover:bg-black/10 rounded"
          >
            <Plus size={20} />
          </button>
        </div>

        <div className={`${isTwoColumnLayout ? 'max-h-[400px] overflow-y-auto' : 'flex-1'}`}>
          <div className={`${isTwoColumnLayout ? 'grid grid-cols-2 gap-2' : 'space-y-2'}`}>
            {tasks.map((task, index) => (
              <div
                key={index}
                draggable={editingTask !== index}
                onDragStart={(e) => handleDragStart(e, { task, sourceId: id, index })}
                onDragEnd={handleDragEnd}
                onDoubleClick={() => {
                  setEditingTask(index);
                  setEditText(task);
                }}
                className={`group bg-white rounded p-2 shadow-sm hover:shadow-md transition-all relative 
                  ${editingTask === index ? '' : 'cursor-move'} hover:bg-gray-50 border border-transparent 
                  hover:border-dashed hover:border-gray-300 min-h-[36px] flex items-center`}
              >
                {editingTask === index ? (
                  <input
                    type="text"
                    value={editText}
                    onChange={(e) => setEditText(e.target.value)}
                    onBlur={() => {
                      if (editText.trim() && editText !== tasks[index]) {
                        onEditTask(id, index, editText.trim());
                      }
                      setEditingTask(null);
                      setEditText('');
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        if (editText.trim() && editText !== tasks[index]) {
                          onEditTask(id, index, editText.trim());
                        }
                        setEditingTask(null);
                        setEditText('');
                      }
                    }}
                    className="w-full p-1 border rounded"
                    autoFocus
                  />
                ) : (
                  <>
                    <div className="pr-6 text-sm flex-1 break-words">{task}</div>
                    <button
                      onClick={() => onDeleteTask(id, index)}
                      className="absolute right-1 top-1/2 -translate-y-1/2 opacity-0 
                        group-hover:opacity-100 hover:bg-red-50 p-1 rounded-full 
                        transition-opacity"
                    >
                      <X size={14} className="text-red-500" />
                    </button>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <AddTaskModal 
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onAdd={(task) => onAddTask(id, task)}
      />
    </>
  );
};

const Dashboard = ({ /* ... existing props ... */ }) => {
  // Initialize sections with default values
  const [sections, setSections] = useState({});

  const fetchTasks = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tasks`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      console.log('Fetched data:', data);
      
      // Remove sections wrapper and mongoDB specific fields
      if (data.sections) {
        const { _id, __v, ...cleanedSections } = data.sections;
        setSections(cleanedSections);
      } else {
        // Handle initial data case
        const { _id, __v, ...cleanedData } = data;
        setSections(cleanedData);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);


  //On change of sections, save to backend
  useEffect(() => {
    saveTasks();
  }, [sections]);

  const saveTasks = async () => {
    try {
      const token = localStorage.getItem('token');
      await fetch(`${process.env.REACT_APP_API_URL}/tasks`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(sections) 
      });
    } catch (error) {
      console.error('Error saving tasks:', error);
    }
  };


  const [dragInfo, setDragInfo] = useState(null);
  const [isExpertScreenOpen, setIsExpertScreenOpen] = useState(false);
  const [isAssistantOpen, setIsAssistantOpen] = useState(false);

  // Handle drag start
  const handleDragStart = (e, info) => {
    setDragInfo(info);
  };
// Handle adding tasks with validation
const handleAddTask = (sectionId, task) => {
  if (!sectionId || !task.trim()) return;

  setSections(prev => ({
    ...prev,
    [sectionId]: {
      ...prev[sectionId],  // Preserve all existing section properties
      tasks: [...(prev[sectionId]?.tasks || []), task.trim()]
    }
  }));
};

// Handle deleting tasks with validation
const handleDeleteTask = (sectionId, taskIndex) => {
  if (!sectionId || taskIndex < 0) return;

  setSections(prev => ({
    ...prev,
    [sectionId]: {
      ...prev[sectionId],  // Preserve all existing section properties
      tasks: prev[sectionId]?.tasks.filter((_, index) => index !== taskIndex) || []
    }
  }));
};

// Handle drag and drop
const handleDrop = (e, targetId) => {
  e.preventDefault();
  if (!dragInfo || !targetId || dragInfo.sourceId === targetId) return;

  const { task, sourceId, index } = dragInfo;
  
  setSections(prev => {
    const newSections = { ...prev };
    const sourceList = [...(prev[sourceId]?.tasks || [])];
    const targetList = [...(prev[targetId]?.tasks || [])];

    sourceList.splice(index, 1);
    targetList.push(task);

    newSections[sourceId] = {
      ...prev[sourceId],  // Preserve all existing section properties
      tasks: sourceList
    };
    newSections[targetId] = {
      ...prev[targetId],  // Preserve all existing section properties
      tasks: targetList
    };

    return newSections;
  });

  setDragInfo(null);
};

  // Handle editing tasks with validation
  const handleEditTask = (sectionId, taskIndex, newText) => {
    if (!sectionId || taskIndex < 0 || !newText.trim()) return;

    setSections(prev => ({
      ...prev,
      [sectionId]: {
        ...prev[sectionId],
        tasks: prev[sectionId]?.tasks.map((task, index) => 
          index === taskIndex ? newText.trim() : task
        ) || []
      }
    }));
  };





  return (
    <div className="fixed inset-0 font-['Patrick_Hand']" style={{ 
      backgroundImage: "url('/bg.png')",
      backgroundSize: '100% 100%'
    }}>
      <div className="absolute inset-0 pt-20 px-6">
        <div className="max-w-7xl mx-auto">
          {/* Header */}
          <header className="flex justify-between items-center px-4">
            <img src="/logo.png" alt="Check.in" className="h-12" />
            <div className="flex gap-6">
              <button 
                className={`flex items-center gap-2 px-6 py-2 rounded-lg shadow-md hover:bg-blue-50 transition-colors
                  ${!isExpertScreenOpen ? 'bg-green-100 text-green-800' : 'bg-white/90'}`}
                onClick={() => setIsExpertScreenOpen(false)}
              >
                <span className="text-xl">🎯</span>
                Dashboard
              </button>
              <button 
                className={`flex items-center gap-2 px-6 py-2 rounded-lg shadow-md hover:bg-blue-50 transition-colors
                  ${isExpertScreenOpen ? 'bg-green-100 text-green-800' : 'bg-white/90'}`}
                onClick={() => setIsExpertScreenOpen(true)}
              >
                <span className="text-xl">🧠</span>
                Talk to expert
              </button>
            </div>
          </header>

          {/* Content */}
          <div className="mt-8">
            {isExpertScreenOpen ? (
              <ExpertSelectionScreen />
            ) : (
              <div className="grid grid-cols-12 gap-6 min-h-[calc(100vh-180px)]">
                {/* Today - Takes 3 columns (1/4 width) and full height */}
                <div className="col-span-3 row-span-2">
                  <TaskCard
                    {...sections.today}
                    onDrop={handleDrop}
                    backColor={"bg-red-200"}
                    onDragStart={handleDragStart}
                    onAddTask={handleAddTask}
                    onDeleteTask={handleDeleteTask}
                    onEditTask={handleEditTask}
                  />
                </div>

                {/* This week - Takes 6 columns (1/2 width) */}
                <div className="col-span-6">
                  <TaskCard
                    {...sections.week}
                    onDrop={handleDrop}
                    backColor={"bg-blue-200"}
                    onDragStart={handleDragStart}
                    onAddTask={handleAddTask}
                    onDeleteTask={handleDeleteTask}
                    onEditTask={handleEditTask}
                  />
                </div>

                {/* This quarter - Takes 3 columns (1/4 width) */}
                <div className="col-span-3">
                  <TaskCard
                    {...sections.quarter}
                    onDrop={handleDrop}
                    backColor={"bg-green-200"}
                    onDragStart={handleDragStart}
                    onAddTask={handleAddTask}
                    onDeleteTask={handleDeleteTask}
                    onEditTask={handleEditTask}
                  />
                </div>

                {/* Bottom row: This month and This year */}
                <div className="col-span-6">
                  <TaskCard
                    {...sections.month}
                    onDrop={handleDrop}
                    backColor={"bg-yellow-200"}
                    onDragStart={handleDragStart}
                    onAddTask={handleAddTask}
                    onDeleteTask={handleDeleteTask}
                    onEditTask={handleEditTask}
                  />
                </div>

                <div className="col-span-3">
                  <TaskCard
                    {...sections.year}
                    onDrop={handleDrop}
                    backColor={"bg-pink-200"}
                    onDragStart={handleDragStart}
                    onAddTask={handleAddTask}
                    onDeleteTask={handleDeleteTask}
                    onEditTask={handleEditTask}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Fixed Chat Window and Avatar */}
      <div className="fixed bottom-6 right-6 z-50 flex flex-col items-end space-y-4">
        {/* Chat Window */}
        {isAssistantOpen && (
          <div className="bg-white rounded-lg shadow-xl w-[400px] h-[600px] flex flex-col overflow-hidden">
            <ChatWindow onClose={() => setIsAssistantOpen(false)} />
          </div>
        )}

        {/* Avatar Button */}
        <button 
          onClick={() => setIsAssistantOpen(!isAssistantOpen)}
          className="w-24 h-24 rounded-full overflow-hidden hover:ring-4 hover:ring-pink-500/50 
            transition-all hover:scale-110 focus:outline-none focus:ring-4 focus:ring-pink-500/50
            shadow-lg bg-white"
        >
          <img 
            src="/avatar.png" 
            alt="AI Assistant" 
            className="w-full h-full object-cover"
          />
        </button>
      </div>
    </div>
  );
};

const App = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <AuthForm />;
  }

  return <Dashboard />;
};

const AppWithAuth = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AppWithAuth;